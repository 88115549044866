const fakelist = {

        lookinvite:{
          one:[
            {
               tapname:'全部订单',
               tapid:0,
            },
            {
               tapname:'待录用',
               tapid:1,
            },   
            {
               tapname:'待完成',
               tapid:2,
            },
            {
               tapname:'已完成',
               tapid:3,
            },
            {
               tapname:'异常工单',
               tapid:4,
            },

          ],
          two:[
            {
               tapname:'全部',
               tapid:99,
            },
            {
               tapname:'草稿箱',
               tapid:0,
            },
            {
               tapname:'待审核',
               tapid:1,
            },
            {
               tapname:'招聘中',
               tapid:2,
            },
            {
               tapname:'已驳回',
               tapid:3,
            },
            {
               tapname:'已下架',
               tapid:5,
            },
          ] ,
          three:[
            {
               tapname:'全部商家',
               tapid:99,
            },
            {
               tapname:'已通过商家',
               tapid:2,
            },
            {
               tapname:'已驳回商家',
               tapid:3,
            },
          ]

        }, 
          newsinvite:{
            one:[
              { label: "临时工", },
              { label: "长期工", },
            ],
            two:[
              { label: "全部",
            value:0 },
              { label: "临时工",
            value:1 },
              { label: "长期工", 
              value:2 },
            ],

          },
          memberlist:{
                    one:[
                      {label:"全部",
                      value:0},
                      {
                        label:"经理",
                        value:3
                      },
                      {label:"超级会员",
                      value:2},
                      {label:"会员",
                      value:1},
                    ],
                    two:[
                      {label:"全部",
                      value:0},
                      {label:"未停用",
                      value:1},
                      {label:"已停用",
                      value:2},
                    ],
                    three:[
                      {label:"全部",
                      value:0},
                      {label:"上班迟到",
                      value:1},
                      {label:"未正常到岗",
                      value:2},
                      {label:"异常结束工作",
                      value:3},
                      {label:"线下恶意交易",
                      value:4},
                    ],
                    four:[
                      {label:"警告",
                    value:1,},
                      {label:"停用一周",
                      value:2,},
                      {label:"停用两周",
                      value:3,},
                      {label:"拉入黑名单",
                      value:4,},
                      {label:"解除处罚",
                      value:5,},
                    ],
                    five:[
                      {label:'全部',
                      value:0},
                      {label:'待审核',
                      value:1},
                      {label:'已审核',
                      value:2}
                    ],
                  },
        merchantlist:{
                    one:[
                      {label:"上班迟到"},
                      {label:"未正常到岗"},
                      {label:"异常结束工作"},
                      {label:"线下恶意交易"},
                    ],
                    two:[
                      {label:"全部",
                    value:0},
                      {label:"未停用",
                    value:1},
                      {label:"已停用",
                      value:2},
                    ],
                    three:[
                      {label:"全部",
                    value:0},
                      {label:"拖欠工资",
                    value:1},
                      {label:"线下恶意交易",
                      value:2},
                    ],
                  },  
       merchantlistpar:{
                    two:[
                      {label:"警告",
                      value:1,},
                        {label:"停用一周",
                        value:2,},
                        {label:"停用两周",
                        value:3,},
                        {label:"拉入黑名单",
                        value:4,},
                        {label:"解除处罚",
                        value:5,},
                    ],
                  },
      directlymenber:{
                    one:[
                      {label:"佣金由高到低",
                      value:1},
                      {label:"粉丝数倒序",
                      value:2},
                      {label:"直推数倒序",
                      value:3},
                    ],
                    two:[
                      {label:"全部会员",
                      value:0},
                      {label:"普通会员",
                      value:1},
                      {label:"超级会员",
                      value:2},
                      {label:"经理",
                      value:3},
                    ],
                  },
      voucherlist:{
        one:[
          {label:"待审核",
          value:2},
          {label:"已拒绝",
          value:3},
          {label:"已发放",
          value:4},
        ],
      },              

      agency:{
        one:[
          {label:"会员升级成为超级会员",
          tapid:0},
          {label:"超级会员升级成经理",
          tapid:1},
        ],
        two:[
          {label:"会员升级成为超级会员",
          tapid:1},
          {label:"超级会员升级成经理",
          tapid:2},
        ],
      },
      community:{
        one:[
          {label:"全部",
          value:0},
          {label:"图片列表",
          value:1},
          {label:"视频列表",
          value:2},
        ],
      },





            // 校验规则
            rules: {
              headline: [//标题：
              { required: true, //是否必填
                message: '请添加标题', //规则
                trigger: 'blur'  //何事件触发
              },
              //可以设置双重验证标准
              // { min: 3, max: 5,  message: '长度在 3 到 5 个字符', }
            ],
            kind: [//工种：
              { required: true, //是否必填
                message: '请选择工种', //规则
                trigger: 'blur'  //何事件触发
              },
            ],
            work: [//工作性质：
              { required: true, //是否必填
                message: '请选择工作性质', //规则
                trigger: 'blur'  //何事件触发
              },
            ],
            salary: [//工价
              { required: true, //是否必填
                message: '请确认工价', //规则
                trigger: 'blur'  //何事件触发
              },
            ],
            recruitingNumbers: [//招聘人数：
              { required: true, //是否必填
                message: '请确认招聘人数', //规则
                trigger: 'blur'  //何事件触发
              },
            ],
            startTime: [//起始工作日期
              { required: true, //是否必填
                message: '请确认起始工作日期', //规则
                trigger: 'blur'  //何事件触发
              },
            ],
            finishTime: [//结束工作日期
              { required: true, //是否必填
                message: '请确认结束工作日期', //规则
                trigger: 'blur'  //何事件触发
              },
            ],
            startHour: [//起始工作时间
              { required: true, //是否必填
                message: '请确认起始时间', //规则
                trigger: 'blur'  //何事件触发
              },
            ],
            finisHour: [//结束工作时间
              { required: true, //是否必填
                message: '请确认结束时间', //规则
                trigger: 'blur'  //何事件触发
              },
            ],
            price_toplat: [//支付招聘费用
              { required: true, //是否必填
                message: '请确认支付招聘费用', //规则
                trigger: 'blur'  //何事件触发
              },
            ],

            companyName: [//公司名称：
              { required: true, //是否必填
                message: '请确认公司名称', //规则
                trigger: 'blur'  //何事件触发
              },
            ],
            linkman: [//联系人：
              { required: true, //是否必填
                message: '请确认联系人', //规则
                trigger: 'blur'  //何事件触发
              },
            ],
            phone: [//联系电话：
              { required: true, //是否必填
                message: '请确认联系电话', //规则
                trigger: 'blur'  //何事件触发
              },
            ],
            companyAddress: [//公司地址：
              { required: true, //是否必填
                message: '请确认公司地址', //规则
                trigger: 'blur'  //何事件触发
              },
            ],
            job: [//职位描述：
              { required: true, //是否必填
                message: '请确认职位描述', //规则
                trigger: 'blur'  //何事件触发
              },
            ],
            remark: [//备注：
              { required: true, //是否必填
                message: '备注', //规则
                trigger: 'blur'  //何事件触发
              },
            ],
            work_addr1: [//备注：
              { required: true, //是否必填
                message: '请确认地址', //规则
                trigger: 'blur'  //何事件触发
              },
            ],
            month_multiple_price: [//备注：月综合工资
              { required: true, //是否必填
                message: '请确认', //规则
                trigger: 'blur'  //何事件触发
              },
            ],
          },


          // 会员列表
          Memberlist:{
            total:10,
            rows:[
              {
               id:"123",
               stop:"2", //停用次数
               
               urlImage: "../assets/img/nav1.png", 
               name:"姓名",
               memberRank:"会员级别",
               invitationCode:"邀请码",
               cellPhone:"手机号",
               gender:"性别",
               age:"年龄",
               registrationTime:"注册时间",
               upOneLevel:"上一级推荐人",
               balanceEnquiry:"余额",
               makeAeal:"成交订单数",
               cancellationOfOrder :"取消订单数",
               shareIncome:"分享收入",
               deadStatus:"停用状态",
               illegalType:"违规类型",
 
               illegalNext:"1", //违规次数
               spiteDeal:"0",//线下恶意交易次数
               breakOff:"1",//用工方中断次数
             },
             {
               id:"6465",
               stop:"3", //停用次数
               
               urlImage: "../assets/img/nav1.png",
               name:"三大1",
               memberRank:"20",
               invitationCode:"2316546",
               cellPhone:"18020099335",
               gender:"男",
               age:"18",
               registrationTime:"2017-1-12",
               upOneLevel:"对撒",
               balanceEnquiry:"100.00",
               makeAeal:"50",
               cancellationOfOrder :"20",
               shareIncome:"60",
               deadStatus:"未停用",
               illegalType:"迟到",
 
               illegalNext:"2", //违规次数
               spiteDeal:"0",//线下恶意交易次数
               breakOff:"5",//用工方中断次数
             },
             {
               id:"3212265",
               stop:"1", //停用次数
               
               urlImage: "../assets/img/nav1.png",
               name:"姓名2",
               memberRank:"会员级别2",
               invitationCode:"邀请码2",
               cellPhone:"手机号2",
               gender:"性别2",
               age:"年龄2",
               registrationTime:"注册时间2",
               upOneLevel:"上一级推荐人2",
               balanceEnquiry:"余额2",
               makeAeal:"成交订单数2",
               cancellationOfOrder :"取消订单数2",
               shareIncome:"分享收入",
               deadStatus:"停用状态",
               illegalType:"违规类型",
 
               illegalNext:"0", //违规次数
               spiteDeal:"0",//线下恶意交易次数
               breakOff:"0",//用工方中断次数
             },
            ]
          },
          // 商家列表
          Merchantlist:{
            total:10,
            rows:[
              {
                id:"32165",

                urlImage: "http://www.wuhangkj.com/weiketd/img/bpimg/5.3.png", //头像
                companyName:"公司名称",
                contacts:"联系人",
                cellPhone:"12345678911",//联系手机号
                contactsAddress:"联系人地址",
                Gender:"性别",
                age:"18",//年龄
                registration:"1999-05-26",//注册时间
                identity:"45555566332211555555",//法人身份证
                uniformCreditCode:"32131561",//统一社会信用代码
                ordersDot:"20",//发布订单数
                registrationson:"10",//报名人数
                finalStatement :"5",//结算人数
                cancel:"2",//取消人数
                blockUp:"停用状态",
                balanceEnquiry:"余额",//500.00
                businessImage: "http://www.wuhangkj.com/weiketd/img/hemoimg/4.3.png", //营业执照
                licenceImage: "http://www.wuhangkj.com/weiketd/img/hemoimg/4.2.png", //派遣许可证
                
                
                arrearsOfWage:"1", //拖欠工资
                maliciousTransactions:"0",//线下恶意交易
                arrearsOfcommission:"1",//拖欠佣金

                blacklist:"1",//黑名单设置 0是 1否
                
              }
            ]
          },
          // 待审核商家
          Unamortized:{
            total:10,
            rows:[
              {
                id:"32165",

                urlImage: "http://www.wuhangkj.com/weiketd/img/bpimg/5.3.png", //头像
                companyName:"公司名称",
                contacts:"联系人",
                cellPhone:"12345678911",//联系手机号
                contactsAddress:"联系人地址",
                gender:"性别",
                age:"188",//年龄
                registration:"1999-05-26",//注册时间
                identity:"45555566332211555555",//法人身份证
                uniformCreditCode:"32131561",//统一社会信用代码

                businessImage: "http://www.wuhangkj.com/weiketd/img/hemoimg/4.3.png", //营业执照
                licenceImage: "http://www.wuhangkj.com/weiketd/img/hemoimg/4.2.png", //派遣许可证
                
              },
              {
                id:"321625",

                urlImage: "http://www.wuhangkj.com/weiketd/img/bpimg/5.3.png", //头像
                companyName:"公司名称2",
                contacts:"联系人2",
                cellPhone:"12345678911",//联系手机号
                contactsAddress:"联系人地址2",
                gender:"性别2",
                age:"1882",//年龄
                registration:"1999-05-26",//注册时间
                identity:"45555566332211555555",//法人身份证
                uniformCreditCode:"32131561",//统一社会信用代码

                businessImage: "http://www.wuhangkj.com/weiketd/img/hemoimg/4.3.png", //营业执照
                licenceImage: "http://www.wuhangkj.com/weiketd/img/hemoimg/4.2.png", //派遣许可证
                
              }
            ]
          },
          // 直属会员
          Directlymenber:{
            total:10,

            commission:"800",//佣金数
            fans:"50",//粉丝数
            directDrive:"33",//直推数
      
            rows:[
              {
                id:"154856",

                name:"姓名",
                commission:"500",//佣金数
                fans:"30",//粉丝数
                directDrive:"20",//直推数
              },
              {
                id:"1556",

                name:"姓名2",
                commission:"5002",//佣金数
                fans:"302",//粉丝数
                directDrive:"202",//直推数
              },
            ]
          },
          // 代理合同表单
          Agency:{
            total:10,
            rows:[
              {
                id:"154856",
                name:"签名",
                
                membership:"会员名",
                cellPhone:"2313546548",//手机号
                applicationTime:"2000-08-11",//申请时间
              },
              {
                id:"1556",
                name:"张作霖",
                
                membership:"会员名2",
                cellPhone:"1111118888999",//手机号
                applicationTime:"3080-08-11",//申请时间
              }
            ]
          },

          // 轮播图
          Slideshow:{
            total:10,
            rows:[
              {
                id:"154856",
                
                slideshowImg:"http://www.wuhangkj.com/weiketd/img/appd/banner.png",//轮播图
                ofType:"详情页", //跳转类型
                detailedIntroduction:"招聘标题", //详情字段

              },
              {
                id:"154856",
                
                slideshowImg:"http://www.wuhangkj.com/weiketd/img/appd/banner.png",//轮播图
                ofType:"宣传图", //跳转类型
                detailedIntroduction:"已停用", //详情字段

              },

            ]
          },
          // 待审核招聘信息
          Auditserve:{
            total:10,
            rows:[
              {
                id:"6616",

                recruitingTime:"2012-08-16",//招聘时间
                
                headUrl: "http://www.wuhangkj.com/weiketd/img/oem/5.1.png",
                titleBar:"标题",
                typeOfWork:"工种",
                quantity:"40",//招聘人数
                labourCost:"10",//工价
                commission:"500",//预估佣金
                character:"工作性质",
                workingHours:"4.5--5.5;8:00--17:00",//工作时间
                companyName:"公司名称",
                linkman:"联系人",
                phone:"1234569874",//联系电话
                companyAddress:"公司地址",
                jobDescription:"职位描述",
                remark:"备注",
                give:"3",//给平台的佣金
                askFor:[//招聘要求
                  {yaoqiu:"第一个"},
                  {yaoqiu:"第二个"},
                  {yaoqiu:"第三个"},
                ],
                // 经纬度
                longitude: "39.0351862510659",
                latitude: "117.68898010253906",
              },
              {
                id:"661106",

                recruitingTime:"2012-08-16",//招聘时间
                
                headUrl: "http://www.wuhangkj.com/weiketd/img/oem/5.3.png",
                titleBar:"标题2",
                typeOfWork:"工种2",
                quantity:"402",//招聘人数
                labourCost:"102",//工价
                commission:"5002",//预估佣金
                character:"工作性质2",
                workingHours:"4.5--5.5;8:00--17:00",//工作时间
                companyName:"公司名称2",
                linkman:"联系人2",
                phone:"1234569874",//联系电话
                companyAddress:"公司地2址",
                jobDescription:"职位描2述",
                remark:"备注",
                give:"3",//给平台的佣金
                askFor:[//招聘要求
                  {yaoqiu:"第一个"},
                  {yaoqiu:"第二个"},
                  {yaoqiu:"第三个"},
                ],
                // 经纬度
                longitude: "39.0351862510659",
                latitude: "118.68898010253906",
              },
            ]
          },
          // 查看招聘信息
          Lookinvite:{
            total:10,
            rows:[
              {
                id:"154856",

                urlImage: "http://www.wuhangkj.com/weiketd/img/bpimg/5.3.png", //头像
                statusCode:"1",//状态码
                status:"已审核",//状态
                arrivalTime:"2500-5-17",//到岗时间
                workingHours:"4.5--5.5;8:00--17:00",//工作时间

                headline:"标题",
                kind:"工种",
                recruitingNumbers:"10",//招聘人数
                salary:"20",//工资
                estimated:"预估佣金",
                work:"工作性质",
                startTime:"2000-12-14",//起始工作日期
                finishTime:"3000-5-12",//结束工作日期
                startHour:"08:00",//起始工作时间
                finisHour:"16:30",//结束工作时间
                askFor:[//招聘要求
                  {yaoqiu:"第一个"},
                  {yaoqiu:"第二个"},
                  {},
                ],
                companyName:"公司名称",
                linkman:"联系人",
                phone:"987594459",//联系电话
                companyAddress:"公司地址",
                job:"职位描述",
                remark:"备注备注备注备注备注",
                give:"5",//给平台的佣金

                // 经纬度
                longitude: "39.0351862510659",
                latitude: "117.68898010253906",

              },
              {
                id:"1548562323",
                
                urlImage: "http://www.wuhangkj.com/weiketd/img/bpimg/5.3.png", //头像
                statusCode:"2",//状态码
                status:"未审核",//状态
                arrivalTime:"2100-5-17",
                workingHours:"4.5--5.5;8:00--17:00",//工作时间

                headline:"标题2",
                kind:"工种2",
                recruitingNumbers:"102",//招聘人数
                salary:"20",//工资
                estimated:"预估佣金2",
                startTime:"2000-12-14",//起始工作日期
                finishTime:"3000-5-12",//结束工作日期
                startHour:"08:00",//起始工作时间
                finisHour:"16:30",//结束工作时间
                askFor:[//招聘要求
                  {yaoqiu:"第一个"},
                  {yaoqiu:"第二个"},
                  {},
                ],
                companyName:"公司名称",
                linkman:"联系人",
                phone:"987594459",//联系电话
                companyAddress:"公司地址",
                job:"职位描述",
                remark:"备注备注备注备注备注",
                give:"5",

                // 经纬度
                longitude: "38.0351862510659",
                latitude: "117.68898010253906",

              },
            ]
          },
          // 等级设置
          Levelset:{
            total:10,
            rows:[
              {
                id:"154856",
                
                gradeName:"等级名称",
                upgrade:"升级条件",
              },
              {
                id:"1548562",
                
                gradeName:"等级名称2",
                upgrade:"升级条件2",
              },
            ]
          },

          // 订单列表
          Orderlist:{
            total:10,
            rows:[
              {
                id:"154856",
                
                stateNumber:1,//状态编号
                state:"未审核",//状态

                manHour:"500",//工时
                commission:'20',//佣金
                totalCommission:'600',//总佣金

                serialNumber:"5665165115",//订单编号
                membership:"会员名称",
                cellPhone:"31651651",//手机号
                orderAmount:"200",//订单金额
                orderTime:"2020-1-22",//下单时间
                onStreamTime:"2020-2-25",//开工时间
                orderStatus:"订单状态",
                recruitment:"招聘方",
              },
              {
                id:"154856ss",
                
                manHour:"5002",//工时
                stateNumber:2,//状态编号
                state:"报名成功",//状态
                commission:'20',//佣金
                totalCommission:'600',//总佣金

                serialNumber:"5665165115",//订单编号
                membership:"会员名称2",
                cellPhone:"31651651",//手机号
                orderAmount:"200",//订单金额
                orderTime:"2020-1-22",//下单时间
                onStreamTime:"2020-2-25",//开工时间
                orderStatus:"订单状态2",
                recruitment:"招聘方2",
              },

            ]
          },
          // 佣金提现
          Commission:{
            // totalAmount:40000,//佣金总额
            // notArrive:10000,//未到账佣金
            // received:30000,//已收到佣金
            rows:[
              {
                id:"154856",
                stateNumber:1,//状态编号
                state:"待发放",//状态

                serialNumber:"5665165115",//订单编号
                membership:"会员名称",
                recruitment:"招聘方",
                orderTime:"2020-1-22",//下单时间
                totalCommission:'600',//总佣金
              },
              {
                id:"152222",
                stateNumber:2,//状态编号
                state:"已发放",//状态

                serialNumber:"5665165115",//订单编号
                membership:"会员名称2",
                recruitment:"招聘方2",
                orderTime:"2020-1-22",//下单时间
                totalCommission:'700',//总佣金
              },
            ]
          },
          // 分享充值
          Share:{
            total:10,
            allShare:600,//分享所得佣金充值
            rows:[
              {
                id:"154856",

                serialNumber:"5665165115",//订单编号
                membership:"会员名称",
                recruitment:"招聘方",
                orderTime:"2020-2-25",//日期
                toSharThe:'20',//分享所得佣金
              },
              {
                id:"154856232",

                serialNumber:"55115",//订单编号
                membership:"会员名称2",
                recruitment:"招聘方2",
                orderTime:"2020-2-25",//日期
                toSharThe:'10',//分享所得佣金
              },
            ]
          },
          // 佣金工资待发放
          Wage:{
            total:10,
            rows:[
              {
                id:"154856",
                stateNumber:1,//状态编号
                state:"待确认",//状态
                totalCommission:'600',//总佣金

                serialNumber:"5665165115",//订单编号
                membership:"会员名称",
                phone:"987594459",//手机号
                orderTime:"2020-1-22",//下单时间
                onStreamTime:"2020-2-25",//开工时间
                recruitment:"招聘方",
                salary:"20",//工资
                manHour:"50",//工时
                commission:"20" ,//佣金
                totalCommission:'600',//总佣金
              },
              {
                id:"1548542146",
                stateNumber:2,//状态编号
                state:"已确认",//状态
                totalCommission:'600',//总佣金

                serialNumber:"566516512115",//订单编号
                membership:"会员名称2",
                phone:"987594459",//手机号
                orderTime:"2020-1-22",//下单时间
                onStreamTime:"2020-2-25",//开工时间
                recruitment:"招聘方2",
                salary:"20",//工资
                manHour:"50",//工时
                commission:"20" ,//佣金
                totalCommission:'600',//总佣金
              },
            ]
          },


          // 协议平台信息
          Agreement:{
            total:10,
            rows:[
              {
                id:"154856",
                titleid:0,//标题的id会员注册协议
                titles:'会员注册协议',
                content:"内容内容内容内容内容内容内容",
              },
              {
                id:"1556",
                titleid:1,//标题的id商家注册协议
                titles:'商家注册协议',
                content:"内容内容内容内容内容内容内容2",
              },
              {
                id:"16",
                titleid:2,//标题的id成为代理的协议
                titles:'成为代理的协议',
                content:"内容内容内容内容内容内容内容3",
              },
              {
                id:"1",
                titleid:3,//标题的id成为经理的协议
                titles:'成为经理的协议',
                content:"内容内容内容内容内容内容内容4",
              },
              {
                id:"856",
                titleid:4,//标题的id平台内容
                titles:'平台内容',
                content:"内容内容内容内容内容内容内容5",
              },
            ]
          },
          // 操作指南
          Operation:{
            total:10,
            rows:[
              {
                id:"154856",
                
                OperationImg:"http://www.wuhangkj.com/weiketd/img/appd/banner.png",//轮播图
                columnName:"栏目名称", 
                contion:"内容", 
              },
              {
                id:"1548562",
                
                OperationImg:"http://www.wuhangkj.com/weiketd/img/appd/banner.png",//轮播图
                columnName:"栏目名称2", 
                contion:"内容2", 
              },
            ]
          },
          // 角色列表
          Rolelist:{
            total:10,
            rows:[
              {
                id:"154856",
                titleid:0,//标题的id会员注册协议
                account:'123456',
                password:"sda1231",
                role:"角色1",
              },
              {
                id:"1546",
                titleid:0,//标题的id会员注册协议
                account:'1234',
                password:"sda123131",
                role:"角色2",
              },
              {
                id:"16",
                titleid:0,//标题的id会员注册协议
                account:'123',
                password:"sda1231",
                role:"角色3",
              },

            ]
          },


          // 社区会员列表信息
          Community:{
            total:10,
            rows:[
              {
                id:"154856",

                titles:'标题',
                content:"内容内容内容内容内容内容内容",
                leixing: 1,
                imageadurl:[
                  {
                    img:"http://www.wuhangkj.com/weiketd/img/vipz/7.01.png"
                  },
                  {
                    img:"http://www.wuhangkj.com/weiketd/img/vipz/7.02.png"
                  },
                  {
                    img:"http://www.wuhangkj.com/weiketd/img/vipz/7.03.png"
                  }
                ]
              },
              {
                id:"156",

                titles:'标题',
                content:"内容内容内容内容内容内容内容",
                leixing: 2,
                imageadurl:'http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4'
              },
              {
                id:"156",

                titles:'标题',
                content:"内容内容内容内容内容内容内容",
                leixing: 1,
                imageadurl:[
                  {
                    img:"http://www.wuhangkj.com/weiketd/img/vipz/7.01.png"
                  },
                  {
                    img:"http://www.wuhangkj.com/weiketd/img/vipz/7.02.png"
                  },
                ]
              },

            ]
          },
          // 收藏管理
          Collect:{
            total:10,
            rows:[
              {
                id:"154856",
                
                titles:"标题",
                money:"100",//金额
                invite:"招聘方1",
                collectDot:"5",//收藏次数
              },
              {
                id:"15485",
                
                titles:"标题2",
                money:"100",//金额
                invite:"招聘方2",
                collectDot:"2",//收藏次数
              },
              {
                id:"1486",
                
                titles:"标题3",
                money:"100",//金额
                invite:"招聘方3",
                collectDot:"5",//收藏次数
              },
            ]
          },
          // 简历列表
          Resume:{
            total:10,
            rows:[
              {
                id:"154856",

                name:"名字",
                urlImage: "http://www.wuhangkj.com/weiketd/img/bpimg/5.3.png", //头像
                gender:"性别",
                cellPhone:"12345678911",//联系手机号
                dateOfBirth:"1999-05-26",//出生日期
                age:"188",//年龄
                education:"学历",
                workExperience:"工作经历",
                technicalAbility:"技术能力",
                selfAssessment:"自我评价",
              },
              {
                id:"15486",

                name:"名子2",
                urlImage: "http://www.wuhangkj.com/weiketd/img/bpimg/5.3.png", //头像
                gender:"性别2",
                cellPhone:"12345678911",//联系手机号
                dateOfBirth:"1999-05-26",//出生日期
                age:"188",//年龄
                education:"学历2",
                workExperience:"工作经历2",
                technicalAbility:"技术能力2",
                selfAssessment:"自我评价2",
              },
            ]
          },
          // 投诉列表
          Complaint:{
            total:10,
            rows:[
              {
                id:"154856",

                urlImage: "http://www.wuhangkj.com/weiketd/img/bpimg/5.3.png", //投诉图片
                complainant:"投诉人",
                phone:"23154648",//电话
                merchant:"被投诉商家",
                time:"2020-2-22",//投诉时间
                complaintHandling:"投诉内容",
                feedback:"会员反馈",
              },
              {
                id:"1556",

                urlImage: "http://www.wuhangkj.com/weiketd/img/bpimg/5.3.png", //投诉图片
                complainant:"投诉人2",
                phone:"23154648",//电话
                merchant:"被投诉商家2",
                time:"2020-2-22",//投诉时间
                complaintHandling:"投诉内容2",
                feedback:"会员反馈2",
              },
            ]
          },
          // 通知列表
          Notification:{
            total:10,
            rows:[
              {
                id:"154856",
                
                inform:"通知内容",
                time:"发送时间",
              },
              {
                id:"1546",
                
                inform:"通知内容2",
                time:"发送时间2",
              },
            ]
          },
          // 会话列表
          Dialogue:{
            total:10,
            rows:[
              {
                id:"154856",
                
                membership:"姓名",
                content:"内容",
              },
              {
                id:"1546",
                
                membership:"姓名2",
                content:"内容2",
              },
            ]
          },
          // 统计管理
          statistics:{
            rows:[
              {
                id:"154856",
                name:"签名",
                
                membership:"会员名",

              },
            ]
          },












}

export default fakelist