<template>
  <div class="Commission">
    <div class="top-title">
      <div class="top-text">佣金提现</div>
    </div>

    <!-- 会员选择 -->
  <div class="select">
      
    <div class="table-tap">
      <div style="font-size:18px;color:red;text-align: center;">可提现总额：{{listpar.unwithdraw_total}}元</div>
        <ul>
          <li> <span>平台总收入：{{listpar.income_total}}元</span></li>
          <li> <span>平台佣金支出：{{listpar.commission_total}}元</span> </li>
          <li> <span>平台净收入：{{listpar.netincome_total}}元</span> </li>
        </ul>
    </div>
    </div>  


     <el-form ref="form" label-width="100px">
          <div class="top-box">

         <el-form-item label="会员姓名" >
                  <el-input v-model="seeks.a"></el-input>
          </el-form-item>

              <el-button
                type="primary"
                class="biy-icon" icon="el-icon-check" @click="seek()"
                >搜索</el-button>
            </div>
        </el-form>
    <!-- 选择列表  表格-->
    <div class="table-form">
      <el-table :data="list" style="width:90%" row-class-name="tableRowStyle" v-loading="loading">
        <el-table-column prop="true_name" label="会员姓名"> </el-table-column>
        <el-table-column prop="member_levelname" label="会员级别"> </el-table-column>
        <el-table-column prop="bu_mobile" label="手机号"> </el-table-column>
        <el-table-column prop="netincome" label="钱包余额"> </el-table-column>
      </el-table>
            <!-- 分页器 -->
      <div class="block" style="margin-top: 15px">
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
// 假数据
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"

export default {
  data() {
    return {
            // 上传图片的地址
      upLoadUrl:config.ImgUrl,
      // 分页
      currentPage: 1, // 当前页码
      total: 10,
      pageSize: 10, // 每页的数据条数
      loading: this.$common.loading,


      // 下拉框
      seeks: {},
      seeklist:fakelist.seeklist,
      // 假数据
      list:[],
      listpar:{}
    };
  },
  created() {
   this.getSong();
   this.getSongs();
  },
  mounted() {

  },

  methods: {
    
    // //查询接口
      async getSong(){
				let res = await apiurl.fetchPageData({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        pageIndex: this.currentPage,
        pageSize: this.pageSize,

        bizCatalog:'WorkerEntity',
        handleMode:'fetchCanWithdraw',
        query:{
          memberName:this.seeks.a,
        }, })
    
        if(this.$common.verifyAjaxResult(res,false)==false) return;
           this.list = res.Result.pageData
           this.total = res.Result.count
           this.loading = false
      },

    // //查询接口
      async getSongs(){
				let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'StatisticsEntity',
        handleMode:'fetchmoney',})
        
        if(this.$common.verifyAjaxResult(res,false)==false) return;
            this.listpar = res.Result
      },


    seek(){
      this.currentPage = 1;
        this.getSong()
    },


    // 分页 每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.pageSize = val;
      this.getSong();
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSong();
    },

  },
  computed: {},
};
</script>


<style  scoped>

.select{
   width: 98%;
  height: 80px;
  padding-top: 0px;
  margin: 0 auto;
  display: flex;
  justify-content: left;
   border-bottom: 2px solid rgb(255, 187, 0);
}

.table-tap{
  width: 680px;
  margin: 0 auto;
}
.table-tap ul{
  padding-top: 20px;
  display: flex;
  justify-content: left;
}
.table-tap ul li{
  width: 33%;
  text-align: center;
}
.table-tap ul li span{
font-size: 16px;
cursor: pointer;
transition: all .3s;
}
.table-tap ul li span.active{
  color: red;
  font-size: 15px;
}
@import "../../assets/css/liststyle.css";
</style>